.modal-window-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  pointer-events: auto;
}

.modal-window-header {
  display: flex;
}

.modal-window-title {
  font-size: 1.3em;
}

.modal-window-line {
  width: 100%;
  margin: 0 0 15px;
  margin: 0;
}

.modal-window-close {
  margin: 0 0 0 auto;
}

.modal-window-content {
  position: relative;
  z-index: 2;
  min-width: 360px;
  min-height: 180px;
  padding: 1em;
  background: #fff;
  text-align: start;
}

.modal-window-hooter {
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.modal-window-submit {
  margin: 0 10px 0 auto;
}