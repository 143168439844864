.unapproved-page {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 30px;
}

.unapproved-hooter {
  position: absolute;
  bottom: 0;
  width: 100%;
}
