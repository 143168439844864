@font-face {
  font-family: 'BIZ UDPGothic';
  src: url('../public/fonts/BIZ_UDPGothic/BIZUDPGothic-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'BIZ UDPGothic';
  src: url('../public/fonts/BIZ_UDPGothic/BIZUDPGothic-Bold.ttf') format('truetype');
  font-weight: bold;
}

* {
  font-family: 'BIZ UDPGothic';
}

body {
  margin: 0;
  font-family: 'BIZ UDPGothic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'BIZ UDPGothic';
}

.index-authenticator {
  margin-top: 100px;
}
