.profile-background {
  /* background: linear-gradient(360deg, #99E9E1 0%, rgba(153, 233, 225, 0) 50%), radial-gradient(28.07% 41.95% at 26.13% 63.22%, rgba(177, 131, 235, 0.5) 0%, rgba(166, 118, 244, 0.148438) 57.29%, rgba(153, 50, 204, 0) 100%), radial-gradient(184.17% 81.03% at 100% 54.02%, #99E9E1 0%, #73AABE 15.9%, #506F9C 34.37%, #344183 55.21%, #2A3079 63.02%, #1C244D 78.65%, #1D2F4F 90.63%); */
  /* background: radial-gradient(35.31% 46% at 28.75% 54%, rgba(147, 112, 219, 0.5) 0%, rgba(147, 112, 219, 0.37) 0.01%, rgba(147, 112, 219, 0) 100%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected, radial-gradient(172.3% 92.88% at 100% 54.02%, #80E2DA 0%, #4477BB 31.25%, #3737AA 52.08%, #1D2A4E 72.92%); */
  background: radial-gradient(35.31% 46% at 28.75% 54%, rgba(147, 112, 219, 0.5) 0%, rgba(147, 112, 219, 0.37) 0.01%, rgba(147, 112, 219, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(152.16% 82.02% at 100% 54.02%, #80E2DA 0%, #4477BB 31.25%, #3737AA 52.08%, #1D2A4E 72.92%);
}

.profile-header-lower {
  background: linear-gradient(272.29deg, #C2F6ED 21.88%, #B8C0EB 76.56%);
}

.profile-details-container {
  overflow: scroll;
  scrollbar-width: none;
}

.profile-details-container::-webkit-scrollbar{
  display:none;
}
/* 

.content{
  scrollbar-width: none;
} */

/* .profile-details-content {
} */

.profile-content {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.profile-content-oneline {
  overflow: hidden;
  text-overflow: ellipsis;
}