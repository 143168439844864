.profile-image-field {
    background-color: #f4f4f4;
    margin: 10px auto;
    padding: 10px;
    border: #ddd dashed 5px;
    min-height: 200px;
    min-width: 400px;
    max-width: 600px;
}

.profile-image-field-dragging-over {
    background-color: #dcdcdc;
    border: #a9a9a9 dashed 5px;
}

.drag-drop-area {
    font-size: 12px;
    font-size: 1.2em;
    height: 400px;
}

.drag-drop-area-label {
    color: #999;
    font-weight: bold;
    font-size: 14px;
    font-size: 1.4em;
}

.profile-image-field-image {
    pointer-events: none;
}