.floor-map-page {
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  display: grid;
  grid-template-rows: 60px 5px auto 0;
}

.floor-map-page-header-none {
  grid-template-rows: 0px auto 0;
  overflow: hidden;
}

.floor-map-header {
  height: 60px;
  width: 100%;
  /* background-color: #FFF; */
  display: flex;
  align-items: center;
  pointer-events: all;
}
.floor-map-header-navy {
  background-color: #15304D;
}

.floor-map-header-navy>*, .floor-map-header-navy>*>* {
  color: white;
}

.floor-map-header-menu-toggle {
  pointer-events: all;
  margin: 0;
  padding: 0 30px;
}

.floor-map-header-menu-toggle-icon {
  font-size: 2rem;
}

.floor-map-header-service-name {
  min-width: 250px;
  text-align: start;
}

.floor-map-header-divider {
  height: 100%;
  margin: 0 10px;
}

.floor-map-header-divider-horizontal {
  width: 100%;
  height: 5px;
  background: linear-gradient(hsl(210deg 6% 70% / 1), hsl(210deg 6% 70% / 0));
}

.floor-map-header-floor-name {
  margin: 0 20px;
  /* min-width: 500px; */
  text-align: start;
  font-size: 1.2rem;
  font-weight: unset;
}

.floor-map-header-icon {
  max-width: 150px;
  max-height: 60px;
  position: absolute;
  right: 20px;
}

.floor-map-main-icon {
  max-width: 150px;
  max-height: 60px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.pointer-events-none {
  pointer-events: none;
}

.floor-map-beer-action-frame {
  pointer-events: all;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
}

.floor-map-main {
  position: relative;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.floor-map-menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  z-index: 4;
  height: 100%;
  width: 400px;
  pointer-events: all;
  background-color: white;
}

.floor-map-menu-copyright {
  position: absolute;
  bottom: 0px;
  left: 100px;
}

.floor-map-background {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  /* height: fit-content;
  width: fit-content; */
  display:inline-block;
  pointer-events: auto;
}

.floor-map-background-image {
  overflow: hidden;
  height: 100%;
  width: 100%;
  object-fit: contain;
  vertical-align: middle;
  margin: 0 auto;
}

.floor-map-icon-group {
  position: absolute;
  pointer-events: none;
  top: -130px;
  bottom: 130px;
  right: 60px;
  left: -60px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.floor-map-icon {
  pointer-events: auto;
}

.floor-map-profile {
  position: absolute;
  z-index: 3;
  top: 5%;
  left: 5%;
  pointer-events: all;
  margin: 0;
}

.floor-map-profile-right {
  left: 75%;
}